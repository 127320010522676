import React from 'react'
import '../styles/Sobrenospagebackground.css'
import SobrenospageBackground from '../assets/sobrenospage_background.png';

function Sobrenospagebackground() {
  return (
    <div className='sobrenospagebackground' style={{ backgroundImage: `url(${SobrenospageBackground})`, backgroundRepeat: 'no-repeat'}}>
        <div className='desktop sobrenospage-right'>

        </div>
        <div className='desktop sobrenospagebackground-desktop'>
            <div className='content-group'>
                <div className='catch'><div className='font-face-comfortaaLight'> Quem somos? </div></div>
                <div className='title'><div className='font-face-comfortaaLight'> Conheça a Sastrack </div></div>
            </div>
        </div>  
        <div className='phone sobrenospagebackground-phone'>
            <div className='content-group'>
                <div className='catch'><div className='font-face-comfortaaLight'> Quem somos? </div></div>
                <div className='title'><div className='font-face-comfortaaLight'> Conheça a Sastrack </div></div>
            </div>
        </div>    
    </div>
  )
}

export default Sobrenospagebackground;