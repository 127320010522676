import React from 'react'
import Landpage from '../components/Landpage';

function Home() {
  return (
    <div>
      <Landpage/>      
    </div>
  )
}

export default Home;