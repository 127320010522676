import React from 'react'
import '../styles/Landpage.css'
import LandpageTracker from '../assets/landpage_tracker.svg';
import LandpageTrackerPhone from '../assets/landpage_tracker_phone.jpg';
import config from "./config.json";

const whatsappUrl = config.WHATSAPP.URL;

function Landpage() {
  return (
    <div className='landpage'>
        <div className='desktop landpage-right'>
            <img src={LandpageTracker} alt='Landpage tracker'/>
        </div>
        <div className='desktop landpage-left'>
            <div className='content-group'>
                <div className='title'><div className='font-face-comfortaaLight'> RASTREAMENTO VEICULAR </div></div>
                <div className='catch'><div className='font-face-comfortaaLight'> Técnologia para você monitorar sua frota </div></div>
                <div className='content'><div className='font-face-comfortaaLight'> A Sastrack foi pensada para trazer segurança e comodidade na palma de sua mão. Através de aplicativo e plataforma web, saiba em tempo real onde estão os seus veículos e muito mais ... </div></div>
                <a href={whatsappUrl}><button type="button" className='landpage-blue-button'> Gerar orçamento </button></a> 
            </div>
        </div>    
        <div className='phone landpage-middle-phone'>
            <div className='phone background'><img src={LandpageTrackerPhone} alt='Landpage tracker'/></div>
            <div className='content-group-phone'>
                <div className='title'><div className='font-face-comfortaaLight'> RASTREAMENTO VEICULAR </div></div>
                <div className='catch'><div className='font-face-comfortaaLight'> Técnologia para você monitorar sua frota </div></div>
                <div className='content'><div className='font-face-comfortaaLight'> Saiba em tempo real onde estão os seus veículos e muito mais ... </div></div>
                <a href={whatsappUrl}><button type="button" className='landpage-blue-button'> Gerar orçamento </button></a> 
            </div>
        </div>    
    </div>
  )
}

export default Landpage;