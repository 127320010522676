import React from 'react'
import '../styles/Whatsapp.css'
import config from "./config.json";
import WhatsappLogo from '../assets/whatsapp_logo_rounded.png';

const whatsappUrl = config.WHATSAPP.URL;

function Whatsapp() {
  return (
    <div className='whatsapp'>
        <a href={whatsappUrl}><div className='logo'><img src={WhatsappLogo} alt='Whatsapp logo' width="60" height="60"/></div></a>
    </div>
  )
}

export default Whatsapp;