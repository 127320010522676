import React from 'react'
import SastrackColorLogo from '../assets/sastrack_color_logo.svg';
import { NavLink } from 'react-router-dom';
import '../styles/Navbar.css'
import { FaBars, FaTimes } from "react-icons/fa";
import config from "./config.json";
import {useState} from 'react'

function Navbar() {
const whatsappUrl = config.WHATSAPP.URL;
const sastrackPlataforma = config.SASTRACK.PLATAFORMA_URL; 

const [toggleMenu, setToggleMenu] = useState(false);
const toggleNav = () => {
    setToggleMenu(!toggleMenu);
}

return (
    <div className='navbar'>      
        <div className='nav-left'>
            <img src={SastrackColorLogo} alt='Logo sastrack'/>
        </div>
        <div className='desktop nav-middle'>
            <NavLink to="/"  style={(state) => state}> <div className='font-face-comfortaaLight'> Home </div> </NavLink>
            <NavLink to="/sobrenos" style={(state) => state}> <div className='font-face-comfortaaLight'>Sobre Nós </div> </NavLink>
            <NavLink to="/contato" style={(state) => state}> <div className='font-face-comfortaaLight'> Contato </div> </NavLink> 
        </div>
        <div className='desktop nav-right'>     
            <a href={whatsappUrl}><button type="button" className='nav-white-button'> Proteger meu veículo </button></a>
            <a href={sastrackPlataforma}><button type="button" className='nav-blue-button'> Acessar plataforma </button></a>
        </div>

        {(!toggleMenu && (
            <div className='phone nav-toggle'>
                <div className='nav-bar' onClick={toggleNav}><FaBars/></div>
            </div>
        ))}
        {(toggleMenu && (
            <div className='phone nav-toggle-on'>
                <div className='nav-bar' onClick={toggleNav}><FaTimes/></div>
                <div className='phone nav-middle'>
                    <div className='logo-mobile'><img src={SastrackColorLogo} alt='Logo sastrack'/></div> 
                    <div className='home-mobile' onClick={toggleNav}><NavLink to="/"> <div className='font-face-comfortaaLight'> Home </div> </NavLink></div>
                    <div className='sobre-nos-mobile' onClick={toggleNav}><NavLink to="/sobrenos"> <div className='font-face-comfortaaLight'> Sobre Nós </div> </NavLink></div>
                    <div className='contato-mobile' onClick={toggleNav}><NavLink to="/contato"> <div className='font-face-comfortaaLight'> Contato </div> </NavLink></div>
                </div>
            </div>

        ))}

    </div>
  )
}

export default Navbar;