import './App.css';
import './index.css';
import Navbar from './components/Navbar';
import Whatsapp from './components/Whatsapp';
import Home from './pages/Home';
import Sobrenos from './pages/Sobrenos';
import Contato from './pages/Contato';
import {BrowserRouter as Router, Route, Routes as Switch} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <div className="App">
      <Router>
        <Navbar/>
        <Whatsapp/>
        <Switch>
          <Route path="/" exact element={<Home/>}/>
          <Route path="/sobrenos" exact element={<Sobrenos/>}/>
          <Route path="/contato" exact element={<Contato/>}/>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
