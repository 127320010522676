import React from 'react'
import '../styles/Contatopage.css'
import ContatopageBackground from '../assets/contatopage_background.png';

import { FaPhoneAlt, FaEnvelope, FaBuilding} from "react-icons/fa";

function Contatopage() {
  return (
    <div className='contatopage' style={{ backgroundImage: `url(${ContatopageBackground})`, backgroundRepeat: 'no-repeat'}}>
        <div className='desktop contatopage-right'>
            <div className='content-group'>
                <div className='form'><div className='font-face-comfortaaLight'> 
                <form>
                    <div><label>EMAIL</label></div>
                    <input className='formemail' type="email" name="user_email"/>
                    <div><label>MENSAGEM</label></div>
                    <textarea name="message"/>
                    <div><label>NOME</label></div>
                    <div><input className='formname' type="text" name="user_name"/><input className='formsend' type="submit" value="ENVIAR"/></div>
                </form> 
                </div></div>
            </div>
        </div>
        <div className='desktop contatopage-left'>
            <div className='content-group'>
                <div className='title'><div className='font-face-comfortaaLight'> Entrar em contato </div></div>
                <div className='catch'><div className='font-face-comfortaaLight'> Estamos aqui por você, como podemos ajudar? </div></div>
                <div className='phone'><div className='font-face-comfortaaLight'> <div className='icons'> <FaPhoneAlt/> </div> (41) 99589-2597 </div></div>
                <div className='email'><div className='font-face-comfortaaLight'> <div className='icons'> <FaEnvelope/> </div> contato@sastrack.com.br </div></div>
                <div className='address'><div className='font-face-comfortaaLight'> <div className='icons'> <FaBuilding/> </div> R. Nelsom Ferreira da Luz, 497 - Campo Comprido, Curitiba - PR, 81220-130 </div></div>
            </div>
        </div>  
        <div className='phone contatopage-middle-phone'>
            <div className='content-group'>
                <div className='title'><div className='font-face-comfortaaLight'> Entrar em contato </div></div>
                <div className='catch'><div className='font-face-comfortaaLight'> Estamos aqui por você, como podemos ajudar? </div></div>
                <div className='phone'><div className='font-face-comfortaaLight'> <div className='icons'> <FaPhoneAlt/> </div> (41) 99589-2597 </div></div>
                <div className='email'><div className='font-face-comfortaaLight'> <div className='icons'> <FaEnvelope/> </div> contato@sastrack.com.br </div></div>
                <div className='address'><div className='font-face-comfortaaLight'> <div className='icons'> <FaBuilding/> </div> R. Nelsom Ferreira da Luz, 497 - Campo Comprido, Curitiba - PR, 81220-130 </div></div>
            </div>
        </div>    
    </div>
  )
}

export default Contatopage;