import React from 'react'
import '../styles/Sobrenospagecontent.css'
import { FaCheckCircle } from "react-icons/fa";
import SobrenospagecontentMap from '../assets/sobrenospagecontent_map.svg';
import config from "./config.json";

const whatsappUrl = config.WHATSAPP.URL;

function Sobrenospagecontent() {
  return (
    <div className='sobrenospagecontent'>
        <div className='desktop sobrenospagecontent-right'>
            <div className='content-group'>
                <div className='catch'>
                    <div className='font-face-comfortaaLight'> 
                        Uma empresa especializada em rastreamento. Prezando pelo bom atendimento ao cliente, entendendo suas necessidades para atendê-los da melhor forma. 
                    </div>
                </div>
                <div className='catch'>
                    <div className='font-face-comfortaaLight'> 
                        Contamos com profissionais extremamente qualificados e uma equipe completa para um atendimento personalizado. Uma empresa especializada em rastreamento. 
                    </div>
                </div>
                <div className='catch'>
                    <div className='font-face-comfortaaLight'> 
                          Prezando pelo bom atendimento ao cliente, entendendo suas necessidades para atendê-los da melhor forma. Contamos com profissionais extremamente qualificados e uma equipe completa para um atendimento personalizado.
                    </div>
                </div>
                <div className='bar'><div className='font-face-comfortaaLight'> </div></div>
                <div className='title'><div className='font-face-comfortaaLight'> Missão </div></div>
                <div className='mission'><div className='font-face-comfortaaLight'> Nós temos como nossa missão dar ao cliente segurança e informações gerenciais dos seus ativos. </div></div>
                <a href={whatsappUrl}><button type="button" className='button'> Monitorar minha frota </button></a> 
            </div>
        </div>
        <div className='desktop sobrenospagecontent-left'>
            <div className='content-group'>
                <div className='bar'><div className='font-face-comfortaaLight'> </div></div>
                <div className='title'><div className='font-face-comfortaaLight'> Nós fazemos o monitoramento de frotas e/ou veículos </div></div>
                <div className='items'><div className='font-face-comfortaaLight'> <div className='icons'><FaCheckCircle/></div> Diversos tipos de relatórios </div></div>
                <div className='items'><div className='font-face-comfortaaLight'> <div className='icons'><FaCheckCircle/></div> Desenvolvemos projetos personalizados </div></div>
                <div className='items'><div className='font-face-comfortaaLight'> <div className='icons'><FaCheckCircle/></div> Apenas rastreadores de primeira </div></div>
                <div className='items'><div className='font-face-comfortaaLight'> <div className='icons'><FaCheckCircle/></div> Crescimento de mais de 100% na região de Curitiba - PR </div></div>
                <div className='map'><div className='font-face-comfortaaLight'> <img src={SobrenospagecontentMap} alt='Sobrenospage map' width={'90%'}/> </div></div>
            </div>
        </div>  
        <div className='phone sobrenospagecontent-phone'>
            <div className='content-group'>
                <div className='bar'><div className='font-face-comfortaaLight'> </div></div>
                <div className='title'><div className='font-face-comfortaaLight'> Nós fazemos o monitoramento de frotas e/ou veículos </div></div>
                <div className='items'><div className='font-face-comfortaaLight'> <div className='icons'><FaCheckCircle/></div> Diversos tipos de relatórios </div></div>
                <div className='items'><div className='font-face-comfortaaLight'> <div className='icons'><FaCheckCircle/></div> Desenvolvemos projetos personalizados </div></div>
                <div className='items'><div className='font-face-comfortaaLight'> <div className='icons'><FaCheckCircle/></div> Apenas rastreadores de primeira </div></div>
                <div className='items'><div className='font-face-comfortaaLight'> <div className='icons'><FaCheckCircle/></div> Crescimento de mais de 100% na região de Curitiba - PR </div></div>
                <div className='map'><div className='font-face-comfortaaLight'> <img src={SobrenospagecontentMap} alt='Sobrenospage map' width={'90%'}/> </div></div>
                <div className='bar'><div className='font-face-comfortaaLight'> </div></div>
                <div className='catch'>
                    <div className='font-face-comfortaaLight'> 
                        Uma empresa especializada em rastreamento. Prezando pelo bom atendimento ao cliente, entendendo suas necessidades para atendê-los da melhor forma. 
                    </div>
                </div>
                <div className='catch'>
                    <div className='font-face-comfortaaLight'> 
                        Contamos com profissionais extremamente qualificados e uma equipe completa para um atendimento personalizado. Uma empresa especializada em rastreamento. 
                    </div>
                </div>
                <div className='catch'>
                    <div className='font-face-comfortaaLight'> 
                          Prezando pelo bom atendimento ao cliente, entendendo suas necessidades para atendê-los da melhor forma. Contamos com profissionais extremamente qualificados e uma equipe completa para um atendimento personalizado.
                    </div>
                </div>
                <div className='bar'><div className='font-face-comfortaaLight'> </div></div>
                <div className='title'><div className='font-face-comfortaaLight'> Missão </div></div>
                <div className='mission'><div className='font-face-comfortaaLight'> Nós temos como nossa missão dar ao cliente segurança e informações gerenciais dos seus veículos. </div></div>
                <a href={whatsappUrl}><button type="button" className='button'> Monitorar minha frota </button></a> 
            </div>
        </div>  
    </div>
  )
}

export default Sobrenospagecontent;