import React from 'react'
import Sobrenospagebackground from '../components/Sobrenospagebackground';
import Sobrenospagecontent from '../components/Sobrenospagecontent';

function Sobrenos() {
  return (
    <div>
      <Sobrenospagebackground/>
      <Sobrenospagecontent/>
    </div>
  )
}

export default Sobrenos;