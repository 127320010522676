import React from 'react'
import Contatopage from '../components/Contatopage';

function Contato() {
  return (
    <div>
      <Contatopage/>
    </div>
  )
}

export default Contato;